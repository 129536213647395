<template>
  <div>
    <div class="wrapper">
      <section class="section section--small-spacing-bottom">
        <div class="shell">
          <back-button
            :title="$t('views.howItWorks.title')"
            @click="$router.push({ name: 'home' })"
          />
          <article class="section__body article-secondary">
            <ul class="cards article__cards">
              <li>
                <section-button
                  :title="$t('views.howItWorks.visit.title')"
                  :picture="require('@/assets/images/icons/ico-camera.svg')"
                  :width="26"
                  :height="21"
                  @click="scroll('#visit')"
                />
              </li>
              <li>
                <section-button
                  :title="$t('views.howItWorks.points.title')"
                  :picture="require('@/assets/images/icons/ico-points.svg')"
                  :width="26"
                  :height="26"
                  @click="scroll('#points')"
                />
              </li>
            </ul>
            <section-block
              id="visit"
              :picture="require('@/assets/images/icons/ico-camera.svg')"
              :width="39"
              :height="32"
              :title="$t('views.howItWorks.visit.title')"
              :content="$t('views.howItWorks.visit.content')"
            />
            <section-block
              id="points"
              :picture="require('@/assets/images/icons/ico-points.svg')"
              :width="39"
              :height="39"
              :title="$t('views.howItWorks.points.title')"
              :content="$t('views.howItWorks.points.content')"
            />
          </article>
        </div>
      </section>
    </div>
    <the-footer
      :logo="logoSrc"
      :logo-src-set="logoSrcSet"
    />
  </div>
</template>

<script setup>
import VueScrollTo from 'vue-scrollto';

import BackButton from '../components/BtnBack.vue';
import SectionButton from '../components/SectionButtonHowItWorks.vue';
import SectionBlock from '../components/SectionHowItWorks.vue';
import TheFooter from '../components/TheFooter.vue';
import { useCompanyLogo } from '../composables/CompanyLogo';

const { logoSrc, logoSrcSet } = useCompanyLogo();

const scroll = (element) => {
  VueScrollTo.scrollTo(element);
};

</script>
<style>
footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
</style>
