<template>
  <div class="card" @click="$emit('click')">
    <figure>
      <img
        :src="picture"
        :width="width"
        :height="height"
        :alt="`icon-${title}`"/>
    </figure>

    <h4>{{ title }}</h4>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
};
</script>
